/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');
require('../css/mobile.scss');
require('../css/tablet.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
// https://symfony.com/doc/current/frontend/encore/legacy-apps.html#accessing-jquery-from-outside-of-webpack-javascript-files
global.$ = global.jQuery = $;

// Auto-hiding navigation based on:
// https://codyhouse.co/gem/auto-hiding-navigation/
var mainHeader = $('header'),
    headerHeight = mainHeader.height();

// Set scrolling variables.
var scrolling = false,
    previousTop = 0,
    currentTop = 0,
    scrollDelta = 1,
    scrollOffsetHideMenu = 100,
    scrollOffsetOpaqueMenu = 5;

$(window).on('scroll', function () {
    // setTimeout(handleScroll, 250);
    if (!scrolling) {
        scrolling = true;
        (!window.requestAnimationFrame)
            ? setTimeout(autoHideHeader, 250)
            : requestAnimationFrame(autoHideHeader);
    }
});

function handleScroll() {
    var currentTop = $(window).scrollTop();

    // On scroll up.
    if (previousTop - currentTop > scrollDelta) {
        checkSections('up');
    }
    // On scroll down.
    else if (currentTop - previousTop > scrollDelta) {
        checkSections('down');
    }

    previousTop = currentTop;
}

$('header').hover(function () {
    mainHeader.addClass('is-hovered');
}, function () {
    mainHeader.removeClass('is-hovered');
    collapseNavMenu();
});

function autoHideHeader() {
    var currentTop = $(window).scrollTop();

    checkSimpleNavigation(currentTop);

    previousTop = currentTop;
    scrolling = false;
}

function collapseNavMenu() {
    if ($(".burger").css("background-image") && $("header nav").is(":visible")) {
        $("header nav").removeClass("is-visible");
        $(".burger").removeClass("menu-visible");
    }
}

function checkSimpleNavigation(currentTop) {
    //if scrolling up...
    if (previousTop - currentTop > scrollDelta) {
        if ($('body.path-frontpage').length > 0) {
            checkSections('up');
        }
        mainHeader.removeClass('is-hidden');
        if (currentTop <= scrollOffsetOpaqueMenu) {
            mainHeader.removeClass('is-opaque');
        }
    }
    //if scrolling down...
    else if (currentTop - previousTop > scrollDelta) {
        if ($('body.path-frontpage').length > 0) {
            checkSections('down');
        }
        if (currentTop > scrollOffsetOpaqueMenu) {
            mainHeader.addClass('is-opaque');
        }
        if (currentTop - previousTop > scrollDelta && currentTop > scrollOffsetHideMenu) {
            mainHeader.addClass('is-hidden');
            collapseNavMenu();
        }
    }
}

// https://css-tricks.com/snippets/javascript/loop-queryselectorall-matches/
function loadAllProgressiveImages() {
    var progressiveBgImages = document.querySelectorAll(".progressive-bg-image");
    [].forEach.call(progressiveBgImages, function(progressiveBgImage) {
        var imgLarge = new Image();
        imgLarge.src = progressiveBgImage.dataset.large;
        imgLarge.onload = function () {
            progressiveBgImage.style.backgroundImage = "url("+progressiveBgImage.dataset.large+")";
            progressiveBgImage.classList.remove('small');
        };
    });
}

loadAllProgressiveImages();
autoHideHeader();


// Lazy load images with .lazy class.
// https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video/
var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

// Using intersection observer.
if ("IntersectionObserver" in window) {
    let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                let lazyImage = entry.target;
                lazyImage.src = lazyImage.dataset.src;
                // lazyImage.srcset = lazyImage.dataset.srcset;
                lazyImage.classList.remove("lazy");
                lazyImageObserver.unobserve(lazyImage);
            }
        });
    }, {
        rootMargin: "0px 0px 256px 0px"
    });

    lazyImages.forEach(function(lazyImage) {
        lazyImageObserver.observe(lazyImage);
    });
}
// Using event handlers (the most compatible way).
else {
    let active = false;

    const lazyLoad = function() {
        if (active === false) {
            active = true;

            setTimeout(function() {
                lazyImages.forEach(function(lazyImage) {
                    if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
                        lazyImage.src = lazyImage.dataset.src;
                        // lazyImage.srcset = lazyImage.dataset.srcset;
                        lazyImage.classList.remove("lazy");

                        lazyImages = lazyImages.filter(function(image) {
                            return image !== lazyImage;
                        });

                        if (lazyImages.length === 0) {
                            document.removeEventListener("scroll", lazyLoad);
                            window.removeEventListener("resize", lazyLoad);
                            window.removeEventListener("orientationchange", lazyLoad);
                        }
                    }
                });

                active = false;
            }, 200);
        }
    };

    document.addEventListener("scroll", lazyLoad);
    window.addEventListener("resize", lazyLoad);
    window.addEventListener("orientationchange", lazyLoad);
}


$(".back-to-top a").click(function() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
});

$(".burger").click(function(){
    $("header nav").toggleClass("is-visible");
    $(this).toggleClass("menu-visible");
});
